/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.3 Level03.glb --transform --keepmeshes
*/

import React, { forwardRef } from 'react'
import { useGLTF } from '@react-three/drei'

export const Level03Model = forwardRef((props, ref) => {
  const { nodes, materials } = useGLTF('models/gltfjsx/Level03-transformed.glb')
  var { setSelectedObject } = props
  const { tableRef } = ref

  return (
    <group {...props} dispose={null}>
      <group name="Scene">
        <group name="Puzzle" position={[0.193, 0.244, 0]}>
          <group ref={tableRef} name="puzzletable" position={[0, 0, 0.5]} scale={[1.317, 0.859, 1.317]} onClick={(obj) => {
            obj.stopPropagation()
            setSelectedObject(obj.eventObject)
          }}>
            <mesh name="Cube004" geometry={nodes.Cube004.geometry} material={materials.Wood} />
            <mesh name="Cube004_1" geometry={nodes.Cube004_1.geometry} material={materials.Metal} />
            <mesh name="Cube004_2" geometry={nodes.Cube004_2.geometry} material={materials.Grid} />
            <mesh name="TableGrid" geometry={nodes.TableGrid.geometry} material={materials.Wood} position={[0, 0.731, 0]} scale={[0.003, 0.023, 0.003]} />
            <mesh name="TableLegs001" geometry={nodes.TableLegs001.geometry} material={materials.Wood} position={[0.324, -0.001, -1.032]} scale={[0.034, 0.053, 0.034]} />
          </group>
        </group>
        <group name="Pieces" position={[0.193, 0.871, -0.505]}>
          <mesh name="PuzzlePiece001" geometry={nodes.PuzzlePiece001.geometry} material={nodes.PuzzlePiece001.material} position={[0.221, 0.013, 0.217]} />
          <mesh name="PuzzlePiece002" geometry={nodes.PuzzlePiece002.geometry} material={nodes.PuzzlePiece002.material} position={[-0.221, 0.013, 0.217]} />
          <mesh name="PuzzlePiece003" geometry={nodes.PuzzlePiece003.geometry} material={nodes.PuzzlePiece003.material} position={[-0.221, 0.013, -0.217]} />
          <mesh name="PuzzlePiece004" geometry={nodes.PuzzlePiece004.geometry} material={nodes.PuzzlePiece004.material} position={[0.221, 0.013, -0.217]} />
        </group>
        <mesh name="ground" geometry={nodes.Ground.geometry} material={materials.Ground} scale={[0.833, 1, 0.984]}
          onClick={(obj) => {
            obj.stopPropagation()
            setSelectedObject(obj.eventObject)
          }}>
          <mesh name="Ceiling" geometry={nodes.Ceiling.geometry} material={materials.Walls} position={[0, 3.244, 0.005]} rotation={[-Math.PI, 0, 0]} />
          <group name="Walls" position={[3, 1.125, 2.54]}>
            <mesh name="Walls_1" geometry={nodes.Walls_1.geometry} material={materials.Walls} />
            <mesh name="Walls_2" geometry={nodes.Walls_2.geometry} material={materials.Walls} />
            <mesh name="WindowFrame" geometry={nodes.WindowFrame.geometry} material={nodes.WindowFrame.material} position={[-2.939, 0.063, -4.969]} scale={[2.399, 1.019, 1.019]}>
              <mesh name="Blind" geometry={nodes.Blind.geometry} material={nodes.Blind.material} position={[0, 0.692, -0.086]} />
              <mesh name="Blind_rails" geometry={nodes.Blind_rails.geometry} material={nodes.Blind_rails.material} />
              <mesh name="WindowL" geometry={nodes.WindowL.geometry} material={nodes.WindowL.material} position={[-0.44, 0.025, 0.023]}>
                <mesh name="Handle" geometry={nodes.Handle.geometry} material={nodes.Handle.material} position={[0.035, 0.37, 0.036]} />
              </mesh>
              <mesh name="WindowR" geometry={nodes.WindowR.geometry} material={nodes.WindowR.material} position={[0.44, 0.025, 0.063]}>
                <mesh name="Handle001" geometry={nodes.Handle001.geometry} material={nodes.Handle001.material} position={[-0.035, 0.37, 0.036]} />
              </mesh>
            </mesh>
            <mesh name="WindowFrame001" geometry={nodes.WindowFrame001.geometry} material={nodes.WindowFrame001.material} position={[-2.939, 0.063, -0.131]} rotation={[Math.PI, 0, Math.PI]} scale={[2.399, 1.019, 1.019]}>
              <mesh name="Blind001" geometry={nodes.Blind001.geometry} material={nodes.Blind001.material} position={[0, 0.692, -0.086]} />
              <mesh name="Blind_rails001" geometry={nodes.Blind_rails001.geometry} material={nodes.Blind_rails001.material} />
              <mesh name="WindowL001" geometry={nodes.WindowL001.geometry} material={nodes.WindowL001.material} position={[-0.44, 0.025, 0.023]}>
                <mesh name="Handle002" geometry={nodes.Handle002.geometry} material={nodes.Handle002.material} position={[0.035, 0.37, 0.036]} />
              </mesh>
              <mesh name="WindowR001" geometry={nodes.WindowR001.geometry} material={nodes.WindowR001.material} position={[0.44, 0.025, 0.063]}>
                <mesh name="Handle003" geometry={nodes.Handle003.geometry} material={nodes.Handle003.material} position={[-0.035, 0.37, 0.036]} />
              </mesh>
            </mesh>
          </group>
        </mesh>
      </group>
    </group>
  )
})

useGLTF.preload('models/gltfjsx/Level03-transformed.glb')
