/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.3 Target.glb --simplify --transform
*/

import React, { forwardRef } from 'react'
import { useGLTF } from '@react-three/drei'

export const Target = forwardRef((props, ref) => {
  const { nodes, materials } = useGLTF('models/Target2.glb')
  return (
    <group ref={ref} {...props} dispose={null} >
      <group position={[0, 3.6, -0.1]} scale={[0.2, 1, 0.2]} >
        <mesh geometry={nodes.Circle002.geometry} material={materials.BlackTarget} />
        <mesh geometry={nodes.Circle002_1.geometry} material={materials.RedTarget} />
      </group>
    </group >
  )
})

useGLTF.preload('models/Target2.glb')
