/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.3 Ring.glb --transform --simplify
*/

import React, { useRef } from 'react'
import { useGLTF, Box } from '@react-three/drei'

export default function Ring(props) {
  const { nodes, materials } = useGLTF('models/gltfjsx/Ring-transformed.glb')
  return (
    <group {...props} dispose={null}>
      <Box args={[.4, .4, .4]} visible={false} />
      <mesh geometry={nodes.Bike_Mesh001.geometry} material={materials.Bike_Chrome} />
      <mesh geometry={nodes.Bike_Mesh001_1.geometry} material={materials.Bike_Tire_Rubber} />
    </group>
  )
}

useGLTF.preload('models/gltfjsx/Ring-transformed.glb')
