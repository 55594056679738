/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.3 WindowBlueprint.glb --simplify --transform
*/

import React, { forwardRef } from 'react'
import {Text, useGLTF} from '@react-three/drei'

export const WindowBlueprint = forwardRef((props, ref) => {
  const { setSelectedObject, xText, yText } = props
  const { nodes, materials } = useGLTF('models/WindowBlueprint-v2.glb')
  return (
    <group name='blueprint' {...props} dispose={null} onPointerDown={(obj) => {
      obj.stopPropagation()
      setSelectedObject(obj.eventObject)
    }
    }>
      <mesh geometry={nodes.WindowBlueprint.geometry} material={materials.WindowBlueprint} position={[13, 7, 5.038]} scale={1}>
          <Text
              color='white'
              fontSize={1.5}
              letterSpacing={0.05}
              position={[-0.1, -0.75, 0]}
              rotation={[0, -Math.PI / 2, 0]}
              scale={[0.1, 0.1, 0.1]}>
              {/*Round the number with 4 digits*/}
              {xText.toFixed(2).replace(".", ",")}m
          </Text>
          <Text
              color='white'
              fontSize={1.5}
              letterSpacing={0.045}
              textAlign={"left"}
              anchorX={"left"}
              position={[-0.1, 0, 0.7]}
              rotation={[0, -Math.PI / 2, 0]}
              scale={[0.1, 0.1, 0.1]}>
              {yText.toFixed(2).replace(".", ",")}m
          </Text>
      </mesh>
    </group>
  )
})

useGLTF.preload('models/WindowBlueprint-v2.glb')
