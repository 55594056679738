/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.3 Sphere.glb --transform --simplify
*/

import React, { useRef } from 'react'
import { useGLTF, Box } from '@react-three/drei'

export default function Sphere(props) {
  const { nodes, materials } = useGLTF('models/gltfjsx/Sphere-transformed.glb')
  return (
    <group {...props} dispose={null}>
      <Box position={[0, .1, 0]} args={[0.3, 0.3, 0.3]} visible={false} />
      <mesh geometry={nodes.Icosphere005.geometry} material={materials.Black} />
      <mesh geometry={nodes.Icosphere005_1.geometry} material={materials['White.003']} />
      <mesh geometry={nodes.Icosphere005_2.geometry} material={materials.Seams} />
    </group>
  )
}

useGLTF.preload('models/gltfjsx/Sphere-transformed.glb')
